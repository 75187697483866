import { graphql } from 'gatsby' 
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css" 
import Img from "gatsby-image"
import img1 from "../../assets/img/agencement/img-1.jpg"
import img2 from "../../assets/img/agencement/img-2.png"
import cover from "../../assets/img/agencement/cover.jpg"

const AgencementPage = ({ data }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true, 
    fade: true,
    asNavFor: '.slider-nav',
    draggable:true, 
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '2px',
    arrows: false,
  };

  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
 
  return(
      <Layout>
        <SEO title="Agencement" />
        <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto md:w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
      <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
        <h1 class="text-center text-2xl mt-5 font-bold">AGENCEMENT</h1>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <img src={cover} alt="cover" />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div>
          <p>Le Solid Surface est un matériau massif et structurel, source de nombreuses solutions techniques et esthétiques lors de la conception d’un chantier d’agencement. Il peut aussi bien servir à l’habillage des murs qu’à la réalisation du mobilier ou des luminaires.
          L’expérience et la polyvalence de nos techniciens nous permettent de répondre à tous types de projets, des plus simples aux plus audacieux.
          Nos compagnons-menuisiers sont à votre écoute et à votre service pour toute question ou tout projet d’agencement intérieur. Nous assurons la création de :</p>
          <ul class="list-disc ml-6 my-2">
            <li class="mb-0">Mobilier design et technique</li>
            <li class="mb-0">Présentoirs</li>
            <li class="mb-0">Placards</li>
            <li class="mb-0">Dressing sur-mesure</li>
          </ul>
          <p>Toutes nos créations sont sur-mesure et se fondent avec naturel dans votre intérieur.
          N’hésitez pas à nous présenter vos projets. Nous nous ferons un plaisir de vous répondre avec précision et rapidité.</p>
        </div>
        <div><img src={img1} alt=" 1" /></div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
        <h1 class="text-center text-2xl font-bold">LES BANQUES D’ACCUEIL</h1>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div><img src={img2} alt=" 2" /></div>
        <div><p>Blanches ou colorées, droites ou thermoformées, les banques d’accueil réalisées en Solid Surface autorisent un design des plus variés. Les possibilités de gravure et de rétro éclairage apportent une forte valeur ajoutée à son aspect décoratif. Ces différentes techniques sont autant de moyens de mettre en valeur l’identité d’une marque ou d’un établissement.
          </p>
        <p class="my-2">
        Toutes nos créations sont sur-mesure et se fondent avec naturel dans votre intérieur.
        </p>
        <p class="my-2">
        N’hésitez pas à nous présenter vos projets. Nous nous ferons un plaisir de vous répondre avec précision et rapidité.</p></div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl">
          <Gallery images={g} />
      </div>
      </Layout> 
 )
}
export const query = graphql`
  query ImagesForGallery {
    gallery:allFile (filter:{relativeDirectory: {eq: "img/agencement/g"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld:allFile (filter:{relativeDirectory: {eq: "img/agencement/s"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default AgencementPage